import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Logos = () => {
    return(
       <>
            <Container>
                <Row>
                    <Col md= "12" className="text-center mt-">
                        <h1>Top 10 Capto-Crypto</h1>
                    </Col>

                </Row>
            </Container>           
       </>
    )
};

export default Logos;